import React, { useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import TestimonialItem from '~2-components/TestimonialItem/TestimonialItem';

const testimonialItemEg = `import TestimonialItem from '~2-components/TestimonialItem/TestimonialItem';

<TestimonialItem
    content='' // Required
    name='' // Optional
/>
`;

const SgTestimonialItem = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const testimonialItems = useStaticQuery(
        graphql`
            query SGAllTestimonial {
                allKontentItemTestimonial {
                    nodes {
                        system {
                            id
                        }
                    }
                }
            }
    `);

    const allTestimonialItems = testimonialItems.allKontentItemTestimonial.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Testimonial Item</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={testimonialItemEg}
                    />

                    <h3>Example - carousel testimonial item</h3>
                </div>
            </section>
            <section className="project-example testimonial-carousel">
                <div className="container">
                    <TestimonialItem
                        content="What a pleasure it’s been to work with Shyla, Nicole and Lauren. I just want to thank you for your commitment, honesty and graciousness. You guys have helped me close a door, move forward AND with my dignity in place. Thank you so much."
                        name="Nataly Ceballos"
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>Example - sidebar testimonial item</h3>
                </div>
            </section>
            <section className="project-example right-col">
                <div className="container">
                    <TestimonialItem
                        content="What a pleasure it’s been to work with Shyla, Nicole and Lauren. I just want to thank you for your commitment, honesty and graciousness. You guys have helped me close a door, move forward AND with my dignity in place. Thank you so much."
                        name="Nataly Ceballos"
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>All LIVE Kontent testimonial items</h3>
                </div>
            </section>
            <section className="project-example testimonial-carousel">
                <div className="container">
                    {allTestimonialItems.map((item, i) => {
                        return (
                            <TestimonialItem
                                key={i}
                                {...item}
                            />
                        );
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgTestimonialItem;
